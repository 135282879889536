import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import "./Variant.css";
import swal from "sweetalert";
import { FaSearch, FaTrash, FaPen, FaCalendar } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
function Variants() {
  const [stock, setStock] = useState([]);
  console.log( "stock",stock )
  const [parentcategory, setParentcategory] = useState("");
  const [variantStock, setVariantStock] = useState("");
  const [varprice, setVarPrice] = useState("");
  const [selectedColour, setSelectedColour] = useState("");
  const [products, setProducts] = useState([]);
  console.log("products:", products);
  const [varientImages, setVarientImages] = useState([]);
  const [storage, setStorage] = useState("");
  const [varientId, setVarientId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [size, setSize] = useState("");
  const [colour, setColour] = useState([]);
  console.log("Colour:", colour);
  console.log("Size:", size);
  const [selectedSize, setSelectedSize] = useState("");
  console.log("Selected Colour:", selectedColour);
  console.log("Selected Size:", selectedSize);
  const [subCategories, setSubCategories] = useState([]);
  const [categoriesload, setCategoriesload] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editVar, setEditVar] = useState({
    id: "",
    product_id: "",
    category_id: "",
    variant_size: "",
    discount_price: "",
    variant_stock: "",
    colour: "",
    price: "",
    storage: "",
    variant_image: [],
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedImagesadd, setSelectedImagesadd] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const [variant, setVariant] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [discountPrice, setDiscountPrice] = useState("");
  const navigate = useNavigate();

  const handleOpenModal = (productId) => {
    fetch(`https://mahalakshmimobiles.com/admin/variant.php?id=${productId}`)
      .then((response) => response.json())
      .then((data) => {
        setModalContent(data);
        setShowModal(true);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleEditModal = (item) => {
    setEditVar(item);
    setEditModal(true);
  };
  const handleAddModal = () => {
    setAddModal(false);
    setParentcategory("");
    setSelectedColour("");
    setSelectedImagesadd([]);
    setSelectedSize("");
    setVariantStock("");
    setVarPrice("");
    setStorage("");
    setDiscountPrice("");
    setVarientImages([]);
  };
  const fetchStock = async () => {
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/admin_get_variant.php"
      );
      const data = await response.json();
      setStock(data || []); // Handle empty data scenario
    } catch (error) {
      console.error("Error fetching data:", error);
      setStock([]); // Ensure state is cleared in case of error
    }
  };

  const handleDelete = async (categoryId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this variant!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/variant.php?id=${categoryId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setStock(stock.filter((category) => category.id !== categoryId));
          swal("Deleted!", "variant has been deleted.", "success");
        } else {
          console.error("Failed to delete category");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleVarUpdate = async () => {
    console.log("editVar", editVar);

    try {
      if (
        !editVar.variant_size ||
        !editVar.price ||
        !editVar.colour ||
        !editVar.storage ||
        !editVar.discount_price ||
        !editVar.variant_image
      ) {
        swal("Warning", "Please enter required fields", "warning");
        return;
      }
      const formData = new FormData();
      formData.append("var_id", editVar.id);
      formData.append("product_id", editVar.product_id);
      formData.append("category_id", editVar.category_id);
      formData.append("variant_size", editVar.variant_size);
      formData.append("variant_status", editVar.variant_status);
      formData.append("discount_price", editVar.discount_price);
      formData.append("colour", editVar.colour);
      formData.append("storage", editVar.storage);
      formData.append("price", editVar.price);

      // Ensure variant_image is an array of File objects
      const variantImages = Array.isArray(editVar.variant_image)
        ? editVar.variant_image
        : [];

      // Append each file to the FormData object
      variantImages.forEach((imageFile, index) => {
        formData.append(`image[]`, imageFile);
      });

      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/edit_variant.php?id=${editVar.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        console.log("Variant updated successfully!");
        setEditModal(false); // Close the edit modal
        fetchStock();
        setSelectedImages([]);
      } else {
        const errorData = await response.json();
        console.error("Failed to update variant:", errorData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page
  };
  const filteredStock = stock.filter(
    (item) =>
      item.product_name &&
      item.product_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Data for current page
  const currentPageData = filteredCapacitie.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredCapacitie.length / itemsPerPage);
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    const visiblePages = 3;
    const totalPages = Math.ceil(filteredCapacitie.length / itemsPerPage);

    // Adjust startPage and endPage based on currentPage
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    // Add leading "..." if startPage is greater than 2
    if (startPage > 2) {
      paginationNumbers.push(1);
      paginationNumbers.push("...");
    }

    // Push the range of page numbers
    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    // Add trailing "..." if endPage is less than totalPages - 1
    if (endPage < totalPages) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const handleCloseModal = (e) => { 
    setShowModal(false);
    setAddModal(false);
    setEditModal(false);
    setSelectedImages([]);
    setSelectedImagesadd([]);
    setSelectedColour("");
    setSelectedSize("");
    setParentcategory("");
    setVariantStock("");
    setVarPrice("");
    setStorage("");
    setVarientImages([]);
    setAddModal(false);
  };
  const handleCloseModal1 = (e) => {
    if (e) e.preventDefault(); 
    setShowModal(false);
    setAddModal(false);
    setEditModal(false);
    setSelectedImages([]);
    setSelectedImagesadd([]);
    setSelectedColour("");
    setSelectedSize("");
    setParentcategory("");
    setSelectedCategory("");
    setSelectedProduct("");
    setSelectedSubCategory("");
    setVariantStock("");
    setVarPrice("");
    setStorage("");
    setVarientImages([]);
    setAddModal(false);
  };
  const Dataget = async () => {
    try {
      // Fetch categories

      const data = await fetch(
        "https://mahalakshmimobiles.com/admin/categoriesmaster.php"
      );
      const response = await data.json();
      setCategoriesload(response);

      const colour = await fetch(
        "https://mahalakshmimobiles.com/admin/colour_master.php"
      );
      const res = await colour.json();
      setColour(res);

      const size = await fetch(
        "https://mahalakshmimobiles.com/admin/size_master.php"
      );
      const res1 = await size.json();
      setSize(res1);
    } catch (error) {
      console.error("Error in Dataget:", error);
    }
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setParentcategory(selectedCategory);
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);
    console.log({ subCategoryId: selectedCategory });
    if (selectedCategory) {
      fetch(
        `https://mahalakshmimobiles.com/admin/categories.php?parent_category=${selectedCategory}`
      )
        .then((response) => response.json())
        .then((data) => {
          setSubCategories(data);
        })
        .catch((error) => {
          console.error("Error fetching sub-categories:", error);
        });
    } else {
      console.error("Selected category not found.");
    }
  };

  const handleSubCategoryChange = (e) => {
    const subCategoryId = e.target.value;
    setSelectedSubCategory(subCategoryId);

    console.log({
      first: `https://mahalakshmimobiles.com/admin/add_variant.php?cat_id=${parentcategory}&&sub_id=${subCategoryId}`,
    });
    fetch(
      `https://mahalakshmimobiles.com/admin/add_variant.php?cat_id=${parentcategory}&&sub_id=${subCategoryId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success === 0) {
          setProducts([]);
        } else {
          setProducts(data);
        }
      })
      .catch((error) => console.error("Error fetching products:", error));
  };

  const handleImageUpload = (e) => {
    setVarientImages(e.target.files);
    const files = Array.from(e.target.files); // Convert FileList to an array
    setSelectedImagesadd(files);
  };

  useEffect(() => {
    setLoading(true);
    Dataget();
    fetchStock()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const handleSortByColumn = (column) => {
    // Determine the sort order (asc or desc)
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";

    // Sort the variant array based on the selected column and sort order
    const sortedVariants = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string for comparison
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string for comparison

      if (newSortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    // Update the state with the sorted data and new sort order
    setVariant(sortedVariants);
    setSortOrder(newSortOrder);
    setSortColumn(column);
  };

  useEffect(() => {
    setVariant(stock);
  }, [stock]);

  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) => {
      const catName = (capacity.cat_name || "").toLowerCase();
      const proName = (capacity.pro_name || "").toLowerCase();
      const colour = (capacity.colour || "").toLowerCase();
      const storage = (capacity.storage || "").toLowerCase();
      const variantSize = (capacity.variant_size || "").toLowerCase();
      const promoCode = (capacity.product_name || "").toLowerCase();

      // Combine fields into a single string for searching
      const combinedFields = `${catName} ${proName} ${colour} ${storage} ${variantSize} ${promoCode}`;

      // Return whether the search query is found in the combined fields
      return combinedFields.includes(searchQuery.toLowerCase());
    });

    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, searchQuery]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to an array
    setSelectedImages(files);
    setEditVar((prevEditVar) => ({
      ...prevEditVar,
      variant_image: files,
    }));
  };

  const handleVariantInsert = async () => {
    try {
      if (
        !varprice ||
        !varientImages.length ||
        !varientImages ||
        !selectedColour ||
        !size ||
        !variantStock ||
        !storage ||
        !selectedProduct ||
        !selectedSubCategory
      ) {
        swal("Warning", "Please enter required fields", "warning");
        return;
      }

      const formData = new FormData();
      formData.append("product_id", selectedProduct); // Ensure selectedProduct is set
      formData.append("category_id", selectedCategory); // Ensure selectedCategory is set
      formData.append("variant_type", "defaultType");
      formData.append("variant_size", selectedSize); // Appending the ID of the size
      formData.append("colour", selectedColour); // Appending the ID of the colour

      // Append the images
      Array.from(varientImages).forEach((imageFile, index) => {
        formData.append(`variant_image_${index}`, imageFile);
      });

      formData.append("variant_stock", variantStock);
      formData.append("variant_status", "1");
      formData.append("offer_status", "No Offer");
      formData.append("discount_price", discountPrice);
      formData.append("storage", storage || "24GB");
      formData.append("price", varprice);
      formData.append("instock_text", "In Stock");
      formData.append("variant_star", 0);
      formData.append("review_count", 0);

      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/variant.php",
        {
          method: "POST",
          body: formData,
        }
      );
      const responseData = await response.text();

      const data = JSON.parse(responseData);
      if (!response.ok) {
        throw new Error(
          `Failed to insert variant: ${response.status} - ${response.statusText}`
        );
      }

      if (data.status === "success") {
        const newVarientId = data.last_id;
        setVarientId(newVarientId);
        setParentcategory("");
        setSelectedColour("");
        setSelectedSize("");
        setVariantStock("");
        setVarPrice("");
        setSelectedImagesadd([]);
        setStorage("");
        fetchStock();
        setSelectedCategory("");
        setSelectedProduct("");
        setSelectedSubCategory("");
        setVarientImages([]);
        setAddModal(false);
        // setProductss
        setSelectedProduct("");
        await stockInsert(newVarientId);
      } else {
        console.error("Failed to add variant:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedColourName, setSelectedColourName] = useState("");
  console.log("selectedColourName", selectedColourName);

  const [selectedSizeName, setSelectedSizeName] = useState("");
  console.log("selectedSizeName", selectedSizeName);
  const handleSelectChange = (event) => {
    const selectedProductId = event.target.value;
    setSelectedProduct(selectedProductId);

    // Find the product object with the selected pro_id
    const selectedProductDetails = products.find(
      (product) => product.pro_id === selectedProductId
    );

    // Set the product name based on the selected product

    setSelectedProductName(selectedProductDetails.pro_name);
  };
  const handleColourChange = (event) => {
    const selectedColourId = event.target.value; // Get the selected colour ID
    setSelectedColour(selectedColourId);
  };

  const handleSizeChange = (event) => {
    const selectedSizeId = event.target.value; // Get the selected size ID
    setSelectedSize(selectedSizeId);
  };

  const stockInsert = async (newVarientId) => {
    const formData = new FormData();
    formData.append("product_id", selectedProduct);
    formData.append("product_name", selectedProductName);
    formData.append("variant_id", newVarientId); // Ensure variant_id is passed correctly
    formData.append("variant_stock", variantStock);
    formData.append("price", varprice);
    formData.append("Colour", selectedColour); // Ensure variant_id is passed correctly
    formData.append("Storage", storage);
    formData.append("Variant_Size", selectedSize);
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/stock.php",
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error(
          `Failed to insert stock: ${response.status} - ${response.statusText}`
        );
      }
      const data = await response.json();
      if (data.status === "success") {
      } else {
        console.error("Failed to insert stock:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Product Variants</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Product Variants
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card-cat ">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
              </div>
            </div>
            <div
              className="d-flex align-items-center ms-auto"
              style={{ padding: "10px" }}
            >
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <FaSearch className="search-icon" />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <button
                  className="add-category-button"
                  onClick={() => setAddModal(true)}
                >
                  Add Variants
                </button>
              </div>
            </div>
          </div>
          <Modal show={addModal} onHide={handleAddModal}>
            <header className="modal-header">
              <h5 className="modal-title">Add Product's Variant</h5>
              <button
                type="button"
                aria-label="Close"
                className="close"
                onClick={handleAddModal}
              >
                ×
              </button>
            </header>
            <Modal.Body>
              <label className="bold">
                Category <i className="text-danger">*</i>
              </label>
              <select
                className="form-select"
                value={parentcategory}
                onChange={handleCategoryChange}
              >
                <option value="">Select Category</option>
                {categoriesload.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
              <label className="bold">
                Sub Category <i className="text-danger ">*</i>
              </label>
              {subCategories.length  === 0 ? (
                <div className="text-muted">Please select a category first</div>
              ) : (
                <select
                  className="form-select"
                  onChange={handleSubCategoryChange}
                >
                  <option value="">Select Sub Category</option>
                  {subCategories.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.category_name}
                    </option>
                  ))}
                </select>
              )}

              <label className="bold">
                Product <i className="text-danger">*</i>
              </label>
              {subCategories.length === 0 ? (
                <div className="text-muted">Please select a category first</div>
              ) : (
                <select
                  className="form-select"
                  value={selectedProduct}
                  onChange={(e) => setSelectedProduct(e.target.value)}
                 
                >
                  <option value="">Select Product</option>
                  {products.map((product) => (
                    <option key={product.pro_id} value={product.pro_id}>
                      {product.pro_name}
                    </option>
                  ))}
                </select>
              )}
              <label className="bold">
                Colour <i className="text-danger ">*</i>
              </label>
              <select
                className="form-select"
                value={selectedColour}
                onChange={handleColourChange}
              >
                <option value="">Select colour</option>
                {colour &&
                  colour.map((colour) => (
                    <option key={colour.id} value={colour.colour}>
                      {colour.colour}
                    </option>
                  ))}
              </select>
              <label className="bold">
                Ram<i className="text-danger ">*</i>
              </label>
              <select
                className="form-select"
                value={selectedSize}
                onChange={handleSizeChange}
              >
                <option value="">Select Variant Size</option>
                {size &&
                  size.map((size) => (
                    <option key={size.id} value={size.size}>
                      {size.size}
                    </option>
                  ))}
              </select>
              <label className="bold">
                Rom<i className="text-danger ">*</i>
              </label>
              <input
                className="form-control"
                placeholder="ROM"
                value={storage}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[a-zA-Z\d,]*$/.test(value)) {
                    setStorage(value);
                  }
                }}
              />

              <label className="bold">
                Stock <i className="text-danger ">*</i>
              </label>
              <input
                className="form-control"
                placeholder=" Stock"
                value={variantStock}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[\d,]*$/.test(value)) {
                    setVariantStock(e.target.value);
                  }
                }}
              />
              <label className="bold">
                Price <i className="text-danger ">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Price"
                value={varprice}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[\d,]*$/.test(value)) {
                    setVarPrice(value);
                  }
                }}
              />
              <label className="bold">
                Discount Price <i className="text-danger ">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Discount Price"
                value={discountPrice}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[\d,]*$/.test(value)) {
                    setDiscountPrice(value);
                  }
                }}
              />

              <label className="bold">
                Images <i className="text-danger ">*</i>
              </label>
              <input
                multiple
                type="file"
                className="form-control"
                onChange={(e) => handleImageUpload(e)}
              />
              <div className="selected-images">
                {selectedImagesadd.map((file, index) => {
                  const imageUrl = URL.createObjectURL(file);
                  return (
                    <img
                      key={index}
                      src={imageUrl}
                      alt={`Selected ${index + 1}`}
                      className="selected-image"
                    />
                  );
                })}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleVariantInsert}>
                Save
              </Button>
              <Button variant="secondary" onClick={(e)=>handleCloseModal1(e)}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="table-container text-end ">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ textAlign: "center" }}>Product Name</th> */}
                    <th
                      onClick={() => handleSortByColumn("cat_name")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Category Name
                      {sortColumn === "cat_name" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th
                      onClick={() => handleSortByColumn("pro_name")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Product Name
                      {sortColumn === "pro_name" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th
                      onClick={() => handleSortByColumn("product_name")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Variant Description
                      {sortColumn === "product_name" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th
                      onClick={() => handleSortByColumn("product_name")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      variant Image
                      {sortColumn === "product_name" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th> */}
                    {/* <th style={{ textAlign: "center" }}>Product Stock</th> */}
                    <th
                      onClick={() => handleSortByColumn("variant_stock")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Stock
                      {sortColumn === "variant_stock" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Price</th> */}
                    <th
                      onClick={() => handleSortByColumn("price")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Box Price
                      {sortColumn === "price" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th style={{ cursor: "pointer", textAlign: "center" }}>Discount Price</th>
                    <th style={{ width: "4%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.length > 0 ? (
                    currentPageData.map((item, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>{item.cat_name}</td>
                        <td style={{ textAlign: "center" }}>{item.pro_name}</td>
                        <td style={{ textAlign: "center" }}>
                          {item.colour},{item.storage} RAM,
                          {item.variant_size} Size
                        </td>
                        {/* <td style={{ textAlign: "center" }}>
                          {" "}
                          <div className="variant-images">
                            {variant.variant_image &&
                              JSON.parse(variant.variant_image).map(
                                (image, imgIndex) => (
                                  <img
                                    key={imgIndex}
                                    src={`https://mahalakshmimobiles.com/admin/image/variant/${image}`}
                                    alt={`Variant ${imgIndex + 1}`}
                                    className="variant-image"
                                  />
                                )
                              )}
                          </div>
                        </td> */}
                        <td style={{ textAlign: "center" }}>
                          {item.variant_stock}
                        </td>
                        <td style={{ textAlign: "center" }}>{item.price}</td>
                        <td style={{ textAlign: "center" }}>{item.discount_price}</td>
                        <td style={{ textAlign: "center" }}>
                          <button
                            className="btn-delete"
                            onClick={() => handleOpenModal(item.id)}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              style={{ cursor: "pointer" }}
                            />
                          </button>
                          <button
                            className="btn-edit"
                            onClick={() => handleEditModal(item)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </button>
                          <button
                            className="btn-edit"
                            onClick={() => handleDelete(item.id)}
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>

            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${page === currentPage ? "active" : ""
                  }`}
              >
                {typeof page === "number" ? (
                  <button
                    onClick={() => setCurrentPage(page)}
                    className="page-link"
                  >
                    {page}
                  </button>
                ) : (
                  <span className="page-link">...</span>
                )}
              </li>
            ))}

            <li
              className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">View Product Variant</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            {modalContent && modalContent.length > 0 ? (
              modalContent.map((variant, index) => (
                <div key={index} className="variant-details">
                  <p>
                    <span className="bold">Variant Size: </span>
                    {variant.variant_size}
                  </p>
                  <p>
                    <span className="bold">Price:</span> {variant.price}
                  </p>
                  <p>
                    <span className="bold">Stock:</span> {variant.variant_stock}
                  </p>
                  <p>
                    <span className="bold">Colour:</span> {variant.colour}
                  </p>
                  <p>
                    <span className="bold">RAM:</span> {variant.storage}
                  </p>
                  <p>
                    <span className="bold">Size:</span> {variant.variant_size}
                  </p>
                  <p>
                    <span className="bold">Discount Price:</span>
                    {variant.discount_price}
                  </p>
                  <p>
                    <span className="bold">Variant Status:</span>

                    {variant.variant_status === "1" ? (
                      <span>Active</span>
                    ) : (
                      <span>In-Active</span>
                    )}
                  </p>
                  <div className="variant-images">
                    {variant.variant_image &&
                      JSON.parse(variant.variant_image).map(
                        (image, imgIndex) => (
                          <img
                            key={imgIndex}
                            src={`https://mahalakshmimobiles.com/admin/image/variant/${image}`}
                            alt={`Variant ${imgIndex + 1}`}
                            className="variant-image"
                          />
                        )
                      )}
                  </div>
                </div>
              ))
            ) : (
              <p>No variants available...!</p>
            )}
          </Modal.Body>
        </Modal>
        <Modal show={editModal} onHide={() => setEditModal(false)}>
          <header className="modal-header">
            <h5 className="modal-title">Edit Variant</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div className="variant-details">
              <div>
                <label className="bold">
                  Variant Size:<i className="text-danger ">*</i>{" "}
                </label>
                <div>
                  <select
                    className="form-select"
                    value={editVar.variant_size}
                    onChange={(e) => {
                      setEditVar({
                        ...editVar,
                        variant_size: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Variant Size</option>
                    {size &&
                      size.map((size) => (
                        <option key={size.id} value={size.size}>
                          {size.size}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div>
                <label className="bold">
                  Price:<i className="text-danger ">*</i>{" "}
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={editVar.price}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Replace non-numeric characters
                    setEditVar({
                      ...editVar,
                      price: numericValue,
                    });
                  }}
                />
              </div>
              <div>
                <label className="bold">Stock: </label>
                <input
                  className="form-control"
                  type="text"
                  value={editVar.variant_stock}
                  readOnly
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Replace non-numeric characters
                    setEditVar({
                      ...editVar,
                      variant_stock: numericValue,
                    });
                  }}
                />
              </div>
              <div>
                <label className="bold">
                  Colour:<i className="text-danger ">*</i>{" "}
                </label>
                <select
                  className="form-select"
                  value={editVar.colour}
                  onChange={(e) => {
                    setEditVar({
                      ...editVar,
                      colour: e.target.value,
                    });
                  }}
                >
                  <option value="">Select Colour</option>
                  {colour &&
                    colour.map((colour) => (
                      <option key={colour.id} value={colour.colour}>
                        {colour.colour}
                      </option>
                    ))}
                </select>
              </div>

              <div>
                <label className="bold">
                  RAM:<i className="text-danger ">*</i>{" "}
                </label>
                <input
                  className="form-control"
                  value={editVar.storage}
                  onChange={(e) =>
                    setEditVar({ ...editVar, storage: e.target.value })
                  }
                />
              </div>
              <div>
                <label className="bold">
                  Discount Price: <i className="text-danger ">*</i>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={editVar.discount_price}
                  onChange={(e) => {
                    const numbersOnly = e.target.value.replace(/[^0-9]/g, ""); // Replace non-numeric characters
                    setEditVar({
                      ...editVar,
                      discount_price: numbersOnly,
                    });
                  }}
                />
              </div>
              <div>
                <label className="bold">
                  Variant Status:<i className="text-danger ">*</i>{" "}
                </label>
                <div className="form-switch formswitch d-flex align-items-center">
                  <input
                    className={`form-check-input custom-switch w-100 ${editVar.variant_status === "1"
                      ? "bg-success"
                      : "bg-danger"
                      }`}
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked={editVar.variant_status === "1"}
                    onChange={(e) => {
                      const newValue = e.target.checked ? "1" : "0";
                      setEditVar({ ...editVar, variant_status: newValue });
                    }}
                  />
                  <label htmlFor="flexSwitchCheckDefault" className="ms-2">
                    {editVar.variant_status === "1" ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>
              <div className="variant-images">
                {/* Check if `variant_image` is a valid JSON string */}
                {editVar.variant_image &&
                  (() => {
                    try {
                      const images = JSON.parse(editVar.variant_image);
                      if (images.length > 0) {
                        return images.map((image, imgIndex) => (
                          <img
                            key={imgIndex}
                            src={`https://mahalakshmimobiles.com/admin/image/variant/${image}`}
                            alt={`Variant ${imgIndex + 1}`}
                            className="variant-image"
                          />
                        ));
                      }
                    } catch (error) {
                      // console.error("Invalid JSON for variant_image:", error);
                      return <p>The Selected images goes below.</p>;
                    }
                  })()}
                <i className="text-danger ">*</i>
                <input
                  type="file"
                  onChange={handleImageChange}
                  className="form-control"
                  multiple
                />

                <div className="selected-images">
                  {selectedImages.map((file, index) => {
                    const imageUrl = URL.createObjectURL(file);
                    return (
                      <img
                        key={index}
                        src={imageUrl}
                        alt={`Selected ${index + 1}`}
                        className="selected-image"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleVarUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Variants;
