import swal from "sweetalert";
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import { faEye } from "@fortawesome/free-solid-svg-icons";
function Auto_Variant_Master() {
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const { id } = location.state || {};
    const Navigate = useNavigate();
    const [parentMaster, setParentMaster] = useState('');
    const [masterName, setMasterName] = useState('');
    const [masters, setMasters] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editMasterId, setEditMasterId] = useState(null);
    const [loading, setLoading] = useState(false);
    const parentMasterInputRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fixedCategoryId = '5';
    const [filteredMasters, setFilteredMasters] = useState([]); // For search
    const [searchQuery, setSearchQuery] = useState(''); // Search query
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        resetForm();
    };

    useEffect(() => {
        if (id) {
            fetchMasters(id);
        }
    }, [id]);
    // const fetchMasters = (categoryId) => {
    //     setLoading(true);
    //     console.log(`https://mahalakshmimobiles.com/admin/admin_get_variant_list.php?var_cat_id=${categoryId}`);
    //     axios.get(`https://mahalakshmimobiles.com/admin/admin_get_variant_list.php?var_cat_id=${categoryId}`)
    //         .then(response => {
    //             console.log('APIwwww response:', response.variant);
    //             // Filter the data to include only items with the specified category_id
    //             const filteredData = response.variant
    //             console.log(' filteredData:',  filteredData);
    //             setMasters(filteredData);
    //             setFilteredMasters(filteredData)
    //             if (filteredData.length > 0) {
    //                 setParentMaster(filteredData[0].category_name); // Set category_name as the parent master
    //                 console.log('parentMaster updated to:', filteredData[0].category_name);
    //             } else {
    //                 setParentMaster(''); // Fallback if no data is found
    //                 console.log('No masters found for the given category');
    //             }
    //         })
    //         .catch(error => console.error('Error fetching data:', error))
    //         .finally(() => setLoading(false));
    // };

    const fetchMasters = (categoryId) => {
        setLoading(true);
    
        const apiUrl = `https://mahalakshmimobiles.com/admin/admin_get_variant_list.php?var_cat_id=${categoryId}`;
        console.log(`Fetching data from: ${apiUrl}`);
    
        axios
            .get(apiUrl)
            .then((response) => {
                console.log('API response:', response.data);
    
                // Access the variant array correctly from response.data
                const filteredData = response.data.variant;
                console.log('Filtered Data:', filteredData);
    
                if (filteredData && filteredData.length > 0) {
                    setMasters(filteredData); // Set the masters state
                    setFilteredMasters(filteredData); // Update filteredMasters state
                    setParentMaster(filteredData[0].category_name || ''); // Use category_name if available
                    console.log('Parent Master updated to:', filteredData[0].category_name);
                } else {
                    setMasters([]);
                    setFilteredMasters([]);
                    setParentMaster('');
                    console.log('No masters found for the given category.');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    const resetForm = () => {

        setMasterName('');
        setEditIndex(null);
        setEditMasterId(null);
    };
    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = masters.filter(master => master.master_name.toLowerCase().includes(query));
        setFilteredMasters(filtered);
    };

    const handleInsert = () => {
        if (!masterName) {
            swal("Error", "Master Name cannot be empty.", "error");
            return;
        }

        setIsSubmitting(true);

        const apiUrl = 'https://mahalakshmimobiles.com/admin/admin_add_variant_list.php';

        const params = editIndex !== null
            ? {
              pro_cat_id: id,
              var_cat_id:id,
              
                variant_name: masterName,
                id: editMasterId,
              
                type: 'UPDATE',
            }
            : {
              // pro_cat_id: id,
              var_cat_id:id,
              variant_name: masterName,
               
            };

        axios.get(apiUrl, { params })
            .then((res) => {
                fetchMasters(id);
                handleCloseModal();
                swal(
                    editIndex !== null ? "Updated!" : "Added!",
                    `Master has been ${editIndex !== null ? "updated" : "added"} successfully.`,
                    "success"
                );
            })
            .catch((error) => {
                console.error(`Error ${editIndex !== null ? "updating" : "adding"} master:`, error);
                swal("Error", "Something went wrong. Please try again.", "error");
            })
            .finally(() => {
                setIsSubmitting(false);  // Enable the button after the operation
            });
    };

    const handleEdit = (index) => {
        const masterToEdit = masters[index];
        // setParentMaster(masterToEdit.parentMaster);
        setMasterName(masterToEdit.variant_name);
        setEditIndex(index);
        setEditMasterId(masterToEdit.id);
        handleOpenModal();
    };
    const handleOpen = (master) => {
        const id = master.id
        const category_id = master.category_id
        Navigate('/ViewMaster', { state: { id, category_id } })
        console.log(master)
    }
    const handleDelete = (index) => {
        const masterToDelete = masters[index];

        // Confirm before delete
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this master!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.get('https://mahalakshmimobiles.com/admin/get_specification_category.php', {
                        params: {
                            id: masterToDelete.id,
                            type: 'DELETE',
                        }
                    })
                        .then((res) => {
                            fetchMasters(id);
                            swal("Deleted!", "Master has been deleted successfully.", "success");
                        })
                        .catch(error => console.error('Error deleting master:', error));
                }
            });
    };
    useEffect(() => {
        console.log('Updated parentMaster:', parentMaster);
    }, [parentMaster]); // This will run whenever parentMaster changes
    return (
        <div className="main-container mt-3">
            <h3 className="head-fon-mv">Masters</h3>

            <Button variant="primary" onClick={handleOpenModal} className="add-category-button" style={{ width: 150 }}>
                Add Master
            </Button>
            <input
                type="text"
                className="form-control w-25 mt-3"
                placeholder="Search Masters"
                value={searchQuery}
                onChange={handleSearchChange}
            />
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editIndex !== null ? 'Edit Master' : 'Add Master'}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        {/* <label className="bold">
                            Parent Master <i className="text-danger">*</i>
                        </label>
                        <input
                            className="form-control"
                            placeholder="Enter Parent Master"
                            value={parentMaster}
                            readOnly // Makes the input non-editable
                        /> */}

                        <label className="bold mt-3">
                        Variant Master Name<i className="text-danger">*</i>
                        </label>
                        <input
                            className="form-control"
                            placeholder="Enter Master Name"
                            value={masterName}
                            onChange={(e) => setMasterName(e.target.value)}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" onClick={handleInsert} disabled={isSubmitting} >
                        {editIndex !== null ? 'Update' : 'Save'}
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <div>


                {loading ? (
                    <div className="d-flex justify-content-center mt-4">
                        <Lottie
                            height={250}
                            width={250}
                            options={{
                                loop: true,
                                animationData: require("../Assets/Animation - 1716197548567.json"),
                                autoplay: true,
                            }}
                        />
                    </div>
                ) : (
                    // <div className="mt-4">
                    //     <h5 className="mb-3">Added Masters</h5>
                    //     {filteredMasters.length === 0 ? (
                    //         <p className="text-center text-muted"> Currently no master for this category.</p>
                    //     ) : (
                    //         <div className="row">
                    //             {filteredMasters.map((master, index) => (
                    //                 <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                    //                     <div className="p-3 border rounded shadow-sm bg-white text-center">
                    //                         <p className="text-muted">{master.master_name}</p>
                    //                         <div className="d-flex justify-content-center mt-2">
                    //                             <button className="btn btn-outline-success btn-sm mx-1" onClick={() => handleOpen(master)}>Open</button>
                    //                             <button className="btn btn-outline-primary btn-sm mx-1" onClick={() => handleEdit(index)}>
                    //                                 Edit
                    //                             </button>
                    //                             <button className="btn btn-outline-danger btn-sm mx-1" onClick={() => handleDelete(index)}>
                    //                                 Delete
                    //                             </button>
                    //                         </div>
                    //                     </div>
                    //                 </div>
                    //             ))}
                    //         </div>
                    //     )}
                    // </div>
                    <div className="mt-4">
                         <h5 className="mb-3">Added Masters</h5>
                      <table id="table-to-xls" className="text-center">
                      <thead>
                        <tr>
                          <th style={{ width: "4%" }}>S.No</th>
                          {/* <th style={{ textAlign: "center" }}>Colour</th> */}
                          <th
                            // onClick={() => handleSortByName()}
                            style={{ cursor: "pointer", textAlign: "center" }}
                          >
                            Variant Master Name
                            {/* {sortOrder === "asc" ? (
                              <FontAwesomeIcon
                                icon={faSortUp}
                                style={{ marginLeft: "8px" }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faSortDown}
                                style={{ marginLeft: "8px" }}
                              />
                            )} */}
                          </th>
                          <th style={{ textAlign: "center" }}>Action</th>
                          {/* <th>Category Name</th> */}
                          {/* {colourview.edit === true || colourview.delete === true ? (
                            <th style={{ textAlign: "center" }}>Action</th>
                          ) : (
                            ""
                          )} */}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredMasters.length ? (
                         filteredMasters.map((colour, index) => (
                            <tr key={colour.id}>
                              <td style={{ textAlign: "center" }}>
                                { index + 1}
                              </td>
                              <td style={{ textAlign: "center" }}>{colour.variant_name}</td>
                              {/* <td>{getCategoryLabels(colour.category_id)}</td> */}
                              {(
                                <td style={{ textAlign: "center" }}>
                                    {/* { (
                                    <button
                                      className="btn-edit"
                                      onClick={() => handleOpen(colour)}
                                    >
                                      <FontAwesomeIcon  icon={faEye} />
                                    </button>
                                  )} */}
                                  { (
                                    <button
                                      className="btn-edit"
                                      onClick={() => handleEdit(index)}
                                    >
                                      <FontAwesomeIcon icon={faPenToSquare} />
                                    </button>
                                  )}
                                  { (
                                    <button
                                      className="btn-delete"
                                      onClick={() => handleDelete(index)}
                                    >
                                      <FaTrash />
                                    </button>
                                  )}
                                </td>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td  style={{ textAlign: "center" }}>-</td>
                            <td
                            //   colSpan={colourview.edit || colourview.delete ? 3 : 2}
                              style={{ textAlign: "center" }}
                            >
                              No records found
                            </td>
                            <td  style={{ textAlign: "center" }}>-</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    </div>
                )}

            </div>
        </div>
    );
}

export default Auto_Variant_Master;
