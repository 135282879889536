import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Addproduct.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Lottie from "react-lottie";
import Swal from "sweetalert2";
import axios from "axios";
import swal from "sweetalert";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import { FaSearch, FaTrash, FaPen, FaCalendar } from "react-icons/fa";
function Addproduct() {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const type = location.state?.product || "";
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state && location.state.product) {
      setId(location.state.product);
    } else {
      console.error("ID not found in location state");
    }
  }, [location.state]);
  const [brandload, setBrandload] = useState([]);
  const [unitload, setUnitLoad] = useState([]);
  const [options, setOptions] = useState([]);
  const [mrpprice, setMrpPrice] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(0);
  const [discount, setDiscount] = useState("");
  const [madeinload, setmadeinload] = useState([]);
  const fileInputRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [productName, setProductName] = useState("");
  const [tax, setTax] = useState("");
  const [unit, setUnit] = useState("");
  const [productType, setProductType] = useState("");
  const [madeIn, setMadeIn] = useState("");
  const [tags, setTags] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [productStock, setProductStock] = useState("");
  const [warranty, setWarranty] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selected, setSelected] = useState("");
  console.log("selected", selected);

  const [productStatus, setProductStatus] = useState("Approved");
  const [isReturnable, setIsReturnable] = useState(true);
  const [isvariant, issetVariant] = useState("0");
  console.log("isvariant", isvariant);


  const [isCancelable, setIsCancelable] = useState(true);
  const [isCODAllowed, setIsCODAllowed] = useState(true);
  const [totalAllowedQuantity, setTotalAllowedQuantity] = useState("");
  const [productInfo, setProductInfo] = useState({
    name: "",
    value: "",
  });
  const [description, setDescription] = useState("");
  const [productFeatures, setProductFeatures] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [variants, setVariants] = useState([]);
  const [variantValues, setVariantValues] = useState({});
  const [tableData, setTableData] = useState([]);
  console.log("tableData", tableData);

  const [taxOptions, setTaxOptions] = useState([]);
  const [showVariations, setShowVariations] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState("");
  const [variantOptions, setVariantOptions] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [variantSize, setVariantSize] = useState("");

  const [variantStock, setVariantStock] = useState("");

  const [productId, setProductId] = useState("");
  const [colour, setColour] = useState("");
  const [storage, setStorage] = useState("");
  const [product, setProduct] = useState(false);

  const [varprice, setVarPrice] = useState("");
  const [varientImages, setVarientImages] = useState([]);
  console.log("varientImages", varientImages);

  const [croppedImage, setCroppedImage] = useState([]);
  console.log("croppedImage", croppedImage);

  const [parent, setParent] = useState(""); // state for selected parent category
  const [CategoriesAll, setCategoriesAll] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const [variantCategory, setVariantCategory] = useState([]);
  const [selectedVariant1, setSelectedVariant1] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const [productId1, setProductId1] = useState(null);
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    console.log('11111',files)
    setVarientImages(files);
  };
  const handleRadioChange = (status) => {
    setProductStatus(status);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    console.log('22222',files)
    setCroppedImage(files);
  };
  useEffect(() => {
    Brandget();
    Unitget();
    Madeinget();
  }, [id]);

  const Unitget = async () => {
    const data = await fetch(
      "https://mahalakshmimobiles.com/admin/addunit.php?"
    );
    const response = await data.json();
    setUnitLoad(response);
  };
  const Brandget = async () => {
    const data = await fetch(
      "https://mahalakshmimobiles.com/admin/brand.php?status=1"
    );
    const response = await data.json();
    setBrandload(response);
  };
  const Madeinget = async () => {
    const data = await fetch(
      "https://restcountries.com/v3.1/independent?status=true"
    );
    const response = await data.json();
    setmadeinload(response);
  };
  useEffect(() => {
    fetch("https://mahalakshmimobiles.com/admin/addtax.php?status=1")
      .then((response) => response.json())
      .then((data) => {
        setTaxOptions(data);
      })
      .catch((error) => {
        console.error("Error fetching tax options:", error);
      });
  }, []);

  useEffect(() => {
    fetchCatData();
  }, []);
  useEffect(() => {
    if (selectedCategory) {
      const fetchAllAttributes = async () => {
        try {
          const response = await fetch(`https://mahalakshmimobiles.com/admin/get_product_specification.php?id=${selectedCategory}`);
          const data = await response.json();
          setCategoriesAll(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchAllAttributes();
    }
  }, [selectedCategory]);
  const fetchCatData = async () => {
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/categoriesmaster.php"
      );
      const data = await response.json();
      setCategories(data);
      console.log("categoriesmaster", data);
      setSelectedVariant(data);
    } catch (error) {
      console.error("Error", error);
    }
  };
  useEffect(() => {
    if (parent) {
      const selectedParentCategory = categories.find(category => category.id === parent);
      setChildCategories(selectedParentCategory?.menu || []);
    }
  }, [parent, categories]);
  const handleCategoryChange = (e) => {
    try {
      if (!e || !e.target) {
        throw new Error("Event object or its target is undefined");
      }
      const categoryId = e.target.value;
      setSelectedCategory(categoryId);
      const selectedCategory = categories.find(
        (category) => category.id === categoryId
      );
      if (selectedCategory) {
        setSelectedCategoryName(selectedCategory.id);
        fetch(
          `https://mahalakshmimobiles.com/admin/categories.php?parent_category=${categoryId}`
        )
          .then((response) => response.json())
          .then((data) => {
            setSubCategories(data);
          })
          .catch((error) => {
            console.error("Error fetching sub-categories:", error);
          });
        const categoryVariants = selectedCategory.variants.split(",");
        setVariants(categoryVariants || []);
        setVariantValues({});
        categoryVariants.forEach((variant) => {
          const [cl, sz] = variant.split(",");
          fetch(`https://mahalakshmimobiles.com/admin/${cl}_master.php`)
            .then((response) => response.json())
            .then((data) => {
              setVariantOptions((prevState) => ({
                ...prevState,
                [cl]: data,
              }));
            })
            .catch((error) => {
              console.error(`Error fetching ${cl} options:`, error);
            });

          fetch(`https://mahalakshmimobiles.com/admin/${sz}_master.php`)
            .then((response) => response.json())
            .then((data) => {
              setVariantOptions((prevState) => ({
                ...prevState,
                [sz]: data,
              }));
            })
            .catch((error) => {
              console.error(`Error fetching ${sz} options:`, error);
            });
        });
      } else {
        console.error("Selected category not found.");
      }
    } catch (error) {
      console.error("Error in handleCategoryChange:", error);
    }
  };
  const handleRadioChangeCan = (e, setIsCancelable, setResolutionDetails) => {
    const { value } = e.target;
    setIsCancelable(value === "Yes");
  };

  //
  const handleSubCategoryChange = (selectedValue) => {
    setSelectedSubCategoryId(selectedValue);
    fetch(`https://mahalakshmimobiles.com/admin/admin_add_product.php?category_id=${selectedCategory}&&subcategory_id=${selectedValue}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch product data");
        }
        return res.json();
      })
      .then((data) => {
        if (data.status === 1 && data.product_id) {
          setProductId1(data.product_id);
        } else {
          console.error("Product ID not found in response");
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
  };
  const handleProductFeaturesChange = (content, delta, source, editor) => {
    setProductFeatures(content);
  };
  const handleRadioChangeCod = (e) => {
    const codValue = e.target.value === "Yes";
    setIsCODAllowed(codValue);
  };
  const handleRadioChangevariant = (e) => {
    issetVariant(e.target.value);
  };
  const handleRadioChangeRe = (e, setIsReturnable) => {
    const { value } = e.target;
    setIsReturnable(value === "Yes");
  };
  function generateRandomProductCode(productName) {
    const randomNumbers = Math.floor(Math.random() * 1000);
    const productCode = `${productName.toUpperCase()}${randomNumbers}`;
    return productCode;
  }
  const [selectedOptions, setSelectedOptions] = useState({});

  const allValues = Object.values(selectedOptions) // Get arrays of selected values for each category
    .flat()
    .join(",");

  console.log("allValues", allValues);

  const handleproductSpecific = (selectedshortby, categoryId) => {
    const selectedValues = selectedshortby.map((option) => option.value); // Get selected IDs
    setSelectedOptions((prevState) => ({
      ...prevState,
      [categoryId]: selectedValues,
    }));
  };

  // const handleVariantCategoryChange = (variantIndex, selectedValue) => {
  //   setSelected((prevSelected) => {
  //     let selectedString = prevSelected || "";
  //     let selectedArray = selectedString.split(",");

  //     while (selectedArray.length <= variantIndex) {
  //       selectedArray.push("");
  //     }
  //     selectedArray[variantIndex] = selectedValue;
  //     return selectedArray.join(",");
  //   });
  // };
  const handleVariantCategoryChange = (variantIndex, selectedValue) => {
    setSelectedVariant1((prev) => ({
      ...prev,
      [variantCategory[variantIndex].variant_name]: selectedValue, // Store value by category name
    }));
  };
  const productCode = generateRandomProductCode(productName);
  const handleInsertProduct = () => {

    setIsLoading(true);
    const formData = new FormData();
    formData.append("product_id", productId1);
    formData.append("category_id", selectedCategory);
    formData.append("category", selectedSubCategoryId);
    formData.append("product_type", productType);
    formData.append("product_name", productName);
    formData.append("product_code", "PRO1234");
    formData.append("product_stock", productStock);
    formData.append("closeing_stock", 0);
    formData.append("warranty", warranty);
    formData.append("tags", tags);
    formData.append("pro_price", price);
    formData.append("discount", discount);
    formData.append("mrpprice", mrpprice);
    formData.append("brand", brand);
    formData.append("description", description);
    formData.append("made_in", selectedCountry);
    formData.append("tax", tax);
    formData.append("product_information", "UKO");
    formData.append("product_features", productFeatures);
    formData.append("returnable", isReturnable ? "Yes" : "No");
    formData.append("cancelable", isCancelable ? "Yes" : "No");
    formData.append("unit", unit);
    formData.append("product_status", productStatus);
    formData.append("cod", isCODAllowed ? "Yes" : "No");
    formData.append("offer_status", 1);
    formData.append("offer_type", 1);
    formData.append("offer_value", 1);
    formData.append("short_by", 1);
    formData.append("product_specification", allValues);
    formData.append("product_code", productCode);
    formData.append("variant_status", isvariant);
    croppedImage.forEach((imageFile) => {
      formData.append("image[]", imageFile);
    });
    formData.append("variants", JSON.stringify(tableData))
    // [{"var_id":"1,3,5,7,9","price":"1500","disPrice":"1300","stock":"15","status":"1","image":"kklkk"}]
    fetch("https://mahalakshmimobiles.com/admin/admin_newadd_product.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Failed to insert");
        }
      })
      .then((data) => {
        // const lastInsertedId = data.last_id;
        swal({
          title: "Success!",
          text: `Product inserted successfully${productId1}`,
          icon: "success",
          button: {
            text: "OK",
            className: "swal-button--success",
          },
          timer: 3000,
        });
        setIsSaved(true);
        setProductId(productId1);
        setProduct(true);
        stockInsertproduct(productId1);
        navigate("/manageproduct");
      })
      .catch((error) => {
        console.log("An error occurred while sending data: " + error.message);
      })
      .finally(() => {
        setIsLoading(false); // Re-enable the button after saving finishes
      });
  };


  //
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };


  const handleVariantInsert = async () => {
    try {

      const validImages = varientImages instanceof FileList ? Array.from(varientImages) : [];
      console.log({ varientImages123: varientImages })
      // console.log({validImages :validImages })
      const data = validImages.map(file => ({
        name: file.name,

      }))
      console.log("data", data);
      console.log("data", selected);
      const newRow = {
        var_id: selected,
        price: varprice,
        disPrice: "00",
        stock: variantStock,
        status: isvariant,
        storage: storage,
        image: varientImages,
        ...selectedVariant1
      };
      //  const tableArray = JSON.stringify(newRow)
      //  const parsedItem = JSON.parse(tableArray);
      setTableData((prevTableData) => [...prevTableData, newRow]);
      setVariantStock("");
      setVarPrice("");
      setStorage("");
      setVarientImages([]);
      setSelectedVariant({});

      setSelected("")


    } catch (error) {
      console.error("Error:", error);
    }


  };

  console.log('first', tableData)



  const handleInsert = async () => {
    try {
      await handleVariantInsert();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const stockInsertproduct = async (lastInsertedId) => {
    const formData = new FormData();
    formData.append("product_id", lastInsertedId);
    formData.append("product_name", productName);
    formData.append("variant_id", "");
    formData.append("variant_stock", productStock);
    formData.append("price", price);
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/stock.php",
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error(
          `Failed to insert stock: ${response.status} - ${response.statusText}`
        );
      }
      const data = await response.json();
      if (data.status === "success") {
      } else {
        console.error("Failed to insert stock:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const varientGetCategory = (selectedCategory) => {
    console.log("varientGetCategory", selectedCategory);
    fetch(`https://mahalakshmimobiles.com/admin/admin_get_variant_category.php?cat_id=${selectedCategory}`)
      .then((res) => res.json())
      .then((data) => {
        setVariantCategory(data)

      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }
  useEffect(() => {
    if (type !== null) {
      setCroppedImage("");
    }
    varientGetCategory(selectedCategory)
  }, [type, selectedCategory]);
  const handleAllDetails = () => {
    setProductName("");
    setTags("");
    setPrice("");
    setBrand("");
    setDescription("");
    setMadeIn("");
    setTax("");
    setProductStock("");
    setWarranty("");
    setMainImage(null);
    setIsReturnable(true);
    setIsCancelable(true);
    setIsCODAllowed(true);
    setProductFeatures("");
    setCroppedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
    setCroppedImage("");
    setTags("");
    setUnit("");
    setProductType("");
    setSelectedCountry("");
    setSelectedCategory("");
    setSelectedSubCategory("");
    setBrand(null);
    setProductStock("");
    setWarranty("");
    setProductStatus("");
    setMainImage("");
    setTableData([]);
    setTotalAllowedQuantity("");
    setSubCategories("");
    setProduct(false);
    setProductInfo({
      name: "",
      value: "",
    });
    // Navigate("/manageproduct");
  };
  const dynamicHeaders = variantCategory.map((item) => item.variant_name);

  const handleDelete = async (categoryId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this variant!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });
      if (shouldDelete) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/variant.php?id=${categoryId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setTableData((prevData) =>
            prevData.filter((row, i) => i !== categoryId)
          );
          swal("Deleted!", "variant has been deleted.", "success");
        } else {
          console.error("Failed to delete category");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  console.log('first', variantCategory)
  return (
    <div className="main-container mt-3">
      {loading ? (
        <div>
          <Lottie
            height={250}
            width={250}
            options={{
              loop: true,
              animationData: require("../Assets/Animation - 1716197548567.json"),
              autoplay: true,
            }}
          />
        </div>
      ) : (
        <div className=" ">
          <div className="flex-container pt-5">
            {type !== "" ? (
              <h3 className="head-fon-mv">Edit Product</h3>
            ) : (
              <h3 className="head-fon-mv">Create Product</h3>
            )}

            <div class="col-12 col-md-6 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-header float-start float-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                  </li>{" "}
                  <li class="breadcrumb-item">
                    <a onClick={() => Navigate("/manageproduct")}>
                      Manage Products
                    </a>
                  </li>{" "}
                  <li aria-current="page" class="breadcrumb-item active">
                    Create Product
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="card ">
            <div className="cat-left">
            </div>
            <div className="form-container">
              <div className="form-column">
                <label className="bold mb-2">
                  Product Name <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Product Name"
                  value={productName || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (/^[a-zA-Z0-9\s'-]*$/.test(value)) {
                      setProductName(value);
                    }
                  }}
                />
                <label className="bold ">Tax</label>
                <select
                  className="form-select"
                  value={tax || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setTax(value);
                  }}
                >
                  <option value="">Select Tax</option>
                  {taxOptions.map((option) => (
                    <option key={option.id} value={option.percentage}>
                      {option.percentage}
                    </option>
                  ))}
                </select>
                <label className="bold  mb-2">
                  Tags<i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Tags"
                  value={tags || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setTags(value);
                  }}
                />
                <label className="bold  mb-2">
                  MRP Price<i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter MRP Price"
                  value={mrpprice || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setMrpPrice(value);
                  }}
                />
                <label className="bold">
                  Brand<i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={brand || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    const newValue = value === "" ? 0 : value;
                    setBrand(newValue);
                  }}
                >
                  <option value="">Select Brand Name</option>
                  {brandload &&
                    brandload.map((brand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.brand_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-column">
                <label className="bold  mb-2">
                  Product Type <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Product Type"
                  value={productType || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setProductType(value);
                  }}
                />
                <label className="bold">
                  Made In <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={selectedCountry || ""}
                  onChange={handleCountryChange}
                >
                  <option value="">Select Country</option>
                  {madeinload &&
                    madeinload.map(
                      (madein) =>
                        madein &&
                        madein.name &&
                        madein.name.common && (
                          <option key={madein.id} value={madein.name.common}>
                            {madein.name.common}
                          </option>
                        )
                    )}
                </select>
                <label className="bold ">
                  Discount<i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  placeholder="Enter Discount"
                  value={discount || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setDiscount(value);
                  }}
                >
                  <option value="" disabled>
                    Select Discount Type
                  </option>
                  <option value="0">Amount</option>
                  <option value="1">Percentage</option>
                </select>
                <label className="bold  mb-2">
                  Price <i className="text-danger">*</i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Price "
                  value={price || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[\d,]*$/.test(value)) {
                      setPrice(value);
                    }
                  }}
                />
                <label className="bold">
                  Category <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={selectedCategory || ""}
                  onChange={(e) => handleCategoryChange(e)}
                >
                  <option value="">Select Category Name</option>
                  {categories &&
                    categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.category_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-column">

                <label className="bold">
                  Sub Category <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={
                    selectedSubCategoryId ||
                    ""
                  }
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    handleSubCategoryChange(selectedValue);
                  }}
                >
                  <option value="">Select Sub Category</option>
                  {subCategories &&
                    subCategories.map((subCategory) => (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.category_name}
                      </option>
                    ))}
                </select>


                <label className="bold">
                  Unit <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={unit || ""}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setUnit(selectedValue);
                  }}
                >
                  <option value="">Select Unit</option>
                  {unitload &&
                    unitload.map((unitItem) => (
                      <option key={unitItem.id} value={unitItem.unit_name}>
                        {unitItem.unit_name}
                      </option>
                    ))}
                </select>


                <label className="bold  mb-2">
                  Opening Stock <i className="text-danger mb-3">*</i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Product Stock"
                  value={productStock}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    const filteredValue = inputVal.replace(/\D/g, "");
                    setProductStock(filteredValue);
                  }}
                  readOnly={isSaved}
                />




                <label className="bold  mb-2">
                  Warranty <i className="text-danger">*</i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Warranty"
                  value={warranty}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    const filteredValue = inputVal.replace(
                      /[^a-zA-Z0-9 ]/g,
                      ""
                    );
                    setWarranty(filteredValue);
                  }}
                />
                <label className="bold mb-2">
                  Main Image <i className="text-danger">*</i>
                </label>

                <label className="mb-2">
                  {croppedImage &&
                    croppedImage.map((file, index) => {
                      console.log("filefile",file)
                      const imageUrl = URL.createObjectURL(file);
                      return (
                        <img
                          key={index}
                          src={imageUrl}
                          alt={`Selected ${index + 1}`}
                          className="selected-image"
                        />
                      );
                    })}
                  <input
                    multiple
                    type="file"
                    className="form-control"
                    onChange={(e) => handleFileChange(e)}
                  />
                  <p style={{ fontSize: 12 }}>Please choose a square image less 140KB.</p>
                  {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                </label>
              </div>
              <div className="form-column">
                <div className="input-group d-flex justify-content-between">
                  {/* Is Returnable */}
                  <label className="bold ">
                    Is Returnable?
                    <div role="radiogroup" className="btn-group-toggle btn-group mb-2">
                      <label
                        className={`btn btn-outline-primary ${isReturnable ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={isReturnable}
                          onChange={(e) => handleRadioChangeRe(e, setIsReturnable)}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${!isReturnable ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={!isReturnable}
                          onChange={(e) => handleRadioChangeRe(e, setIsReturnable)}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>

                  {/* Is Cancelable */}
                  <label className="bold">
                    Is Cancelable?
                    <div role="radiogroup" className="btn-group-toggle btn-group mb-2">
                      <label
                        className={`btn btn-outline-primary ${isCancelable ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={isCancelable}
                          onChange={(e) => handleRadioChangeCan(e, setIsCancelable)}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${!isCancelable ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={!isCancelable}
                          onChange={(e) => handleRadioChangeCan(e, setIsCancelable)}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>

                  {/* Is COD Allowed */}
                  <label className="bold">
                    Is COD Allowed?
                    <div role="radiogroup" className="btn-group-toggle btn-group mb-2">
                      <label
                        className={`btn btn-outline-primary ${isCODAllowed ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={isCODAllowed}
                          onChange={(e) => handleRadioChangeCod(e, setIsCODAllowed)}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${!isCODAllowed ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={!isCODAllowed}
                          onChange={(e) => handleRadioChangeCod(e, setIsCODAllowed)}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>

                  {/* Product Status */}
                  <label className="bold">
                    Product Status
                    <div role="radiogroup" className="btn-group-toggle btn-group mb-2">
                      <label
                        className={`btn btn-outline-primary ${productStatus === "Approved" ? "active" : ""
                          }`}
                      >
                        <input
                          type="radio"
                          value="Approved"
                          checked={productStatus === "Approved"}
                          onChange={() => handleRadioChange("Approved")}
                        />
                        <span>Approved</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${productStatus === "Not-Approved" ? "active" : ""
                          }`}
                      >
                        <input
                          type="radio"
                          value="Not-Approved"
                          checked={productStatus === "Not-Approved"}
                          onChange={() => handleRadioChange("Not-Approved")}
                        />
                        <span>Not Approved</span>
                      </label>
                    </div>
                  </label>
                  <label className="bold">
                    Is Variant?
                    <div role="radiogroup" className="btn-group-toggle btn-group mb-2">
                      {/* Yes Button */}
                      <label
                        className={`btn btn-outline-primary ${isvariant === "1" ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="1"
                          checked={isvariant === "1"}
                          onChange={handleRadioChangevariant}
                        />
                        <span>Yes</span>
                      </label>

                      {/* No Button */}
                      <label
                        className={`btn btn-outline-primary ${isvariant === "0" ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="0"
                          checked={isvariant === "0"}
                          onChange={handleRadioChangevariant}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>

                </div>
              </div>
            </div>





            {selectedCategory ? (
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    {Array.isArray(CategoriesAll) && CategoriesAll.length > 0 ? (
                      CategoriesAll.map((category) => (
                        <div key={category.id} className="col-md-4 d-flex flex-column">
                          <label className="bold">{category?.master_name}</label>
                          <Select
                            id={`categorySelect-${category.id}`}
                            className="form-control"
                            isMulti
                            onChange={(selectedshortby) =>
                              handleproductSpecific(selectedshortby, category.id)
                            }
                            placeholder="Select Subcategory By"
                            options={category.menu?.map((childCategory) => ({
                              value: childCategory.id,
                              label: childCategory.menu_name,
                            }))}
                            value={selectedOptions[category.id]?.map((childId) => ({
                              value: childId,
                              label: category.menu.find(
                                (child) => child.id === childId
                              )?.menu_name,
                            })) || []} // Set selected options based on state
                          />
                        </div>
                      ))
                    ) : (
                      <p>No categories available</p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <p>No category selected</p>
            )}
            <div className="d-flex gap-3">
              <div style={{ flex: 1 }}>
                <label className="bold">
                  Description <i className="text-danger">*</i>
                </label>
                <ReactQuill
                  theme="snow"
                  value={description || ""}
                  onChange={handleDescriptionChange}
                  style={{}}
                />
              </div>
              <div style={{ flex: 1 }}>
                <label className="bold">
                  Product Features <i className="text-danger">*</i>
                </label>
                <ReactQuill
                  theme="snow"
                  value={productFeatures || ""}
                  onChange={handleProductFeaturesChange}
                  style={{}}
                />
              </div>
            </div>

            <div>

            </div>
            {isvariant === "0" ? (
              <div className="save-bt ">
                <button
                  className="btn btn-danger "

                  style={{ marginRight: "10px", width: "90px" }}
                  onClick={() => navigate("/manageproduct")}
                >
                  Cancel
                </button>
                <button
                  onClick={handleInsertProduct}
                  disabled={isLoading}
                  className="btn btn-success "
                  style={{ width: "90px", float: "left" }}
                >
                  {isLoading
                    ? id
                      ? "Updating..."
                      : "Saving..."
                    : id
                      ? "Update"
                      : "Save"}
                </button>
              </div>

            ) : null}
          </div>

          <div>
            {isvariant === "1" ? (
              <>
                <label className="bold"> Add Variant </label>
                <div className="">

                  {/* Form Section */}
                  <div className="form-container2">
                    <form onSubmit={handleSubmit}>
                      <div className="container">
                        {/* First Row: 3 Inputs in One Row */}
                        <div className="row mb-2">
                          <div className="col-md-4">
                            <label className="col-form-label">Variant Stock:</label>
                            <input
                              className="form-control"
                              placeholder="Variant Stock"
                              value={variantStock}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  setVariantStock(value);
                                }
                              }}
                            />
                          </div>
                          <div className="col-md-4">
                            <label className="col-form-label">Price:</label>
                            <input
                              className="form-control"
                              placeholder="Price"
                              value={varprice}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  setVarPrice(value);
                                }
                              }}
                            />
                          </div>
                          <div className="col-md-4">
                            <label className="col-form-label">Images:</label>
                            <input
                              ref={fileInputRef}
                              multiple
                              type="file"
                              className="form-control"
                              onChange={handleImageUpload}
                            />
                          </div>
                        </div>

                        {/* Second Row: Variant Category */}
                        <div className="container">
                          <div className="row">
                            {variantCategory.map((item, index) => (
                              <div className="col-md-4 mb-2" key={index}>
                                <label className="col-form-label">{item.variant_name}:</label>
                                <select
                                  className="form-select"
                                  onChange={(e) => handleVariantCategoryChange(index, e.target.value)}
                                >
                                  <option value="">Select {item.variant_name}</option>
                                  {item.variant_name_list.map((variant, variantIndex) => (
                                    <option key={variantIndex} value={`${variant}`}>
                                      {variant}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ))}
                          </div>
                        </div>


                        {/* Third Row: Add Button */}
                        <div className="row">
                          <div className="col text-center">
                            <button
                              type="button"
                              onClick={handleInsert}
                              style={{
                                padding: "5px 30px",
                                backgroundColor: "#007bff",
                                color: "#fff",
                                border: "none",
                                borderRadius: "3px",
                                cursor: "pointer",
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>


                  {/* Table Section */}
                  <div className="col-sm-9">
                    <h2>Variant Data</h2>
                    <table border="1" className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Variant Stock</th>
                          <th>Price</th>
                          <th>Images</th>

                          {/* Dynamic Headers for Variant Categories (Colour, RAM, Rom, etc.) */}
                          {dynamicHeaders.map((header, index) => (
                            <th key={index}>{header}</th>
                          ))}

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((rowData, index) => (
                          <tr key={index}>
                            <td>{rowData.stock}</td>
                            <td>{rowData.price}</td>

                            {/* Displaying Images */}
                            <td>
                              {rowData.image.length > 0 ? (
                                rowData.image.map((file, idx) => {
                                  console.log('file11111111111111111',file)
                                  const imageUrl = URL.createObjectURL(file); // Convert to a usable URL
                                  return (
                                    <img
                                      key={idx}
                                      src={imageUrl}
                                      alt={`Selected ${idx + 1}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                        marginRight: "5px",
                                      }}
                                    />
                                  );
                                })
                              ) : (
                                <span>No Imagess</span>
                              )}

                            </td>

                            {/* Dynamically Display Variant Values */}
                            {dynamicHeaders.map((header, idx) => (
                              <td key={idx}>{rowData[header] || "-"}</td> // Show value or "-" if missing
                            ))}

                            <td>
                              <button className="btn-edit" onClick={() => handleDelete(index)}>
                                <FaTrash />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>



                </div>
                <div className="save-bt d-flex gap-2">
                  <button
                    className="btn btn-danger btn-sm "
                    onClick={() => navigate("/manageproduct")}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleInsertProduct}
                    disabled={isLoading}
                    className="btn btn-success btn-sm"
                  >
                    {isLoading ? (id ? "Updating..." : "Saving...") : id ? "Update" : "Save"}
                  </button>
                </div>

              </>
            ) : null}



          </div>

        </div>
      )}
    </div>
  );
}

export default Addproduct;

