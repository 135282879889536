import "./App.css";
import Login from "./Components/Login/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./Components/Layout/Layout";
import Button from 'react-bootstrap/Button';


import Dashboard from "./Components/Dashboard/Dashboard";
import Categories from "./Components/Categories/Categories";
import Orderlist from "./Components/Orderlist/Orderlist";
import Addcategories from "./Components/Addcategories/Addcategories";
import Categoriesorder from "./Components/Categoriesorder/Categoriesorder";
import Productsalesreport from "./Components/Productsalesreport/Productsalesreport";
import Salesreport from "./Components/SalesReport/Salesreport";
import Units from "./Components/Units/Units";
import Taxes from "./Components/Taxes/Taxes";
import Addproduct from "./Components/Product/Addproduct";
import Brands from "./Components/Brands/Brands";
import Homeslider from "./Components/Homeslider/Homeslider";
import Managehomeslider from "./Components/Managehomeslider/Managehomeslider";
import Withdrawalrequest from "./Components/Withdrawalrequest/Withdrawalrequest";
import ReturnRequest from "./Components/ReturnRequest/ReturnRequest";
import Systemusers from "./Components/Systemusers/Systemusers";
import Offerimage from "./Components/OfferImages/Offerimage";
import Manageimage from "./Components/Manageimage/Manageimage";
import Addpromo from "./Components/AddPromo/Addpromo";
import Managepromo from "./Components/ManagePromo/Managepromo";
import Adddelivery from "./Components/Delivery/Adddelivery";
import Managedelivery from "./Components/Delivery/Managedelivery";
import Addsection from "./Components/Sections/Addsection";
import Managesection from "./Components/Sections/Managesection";
import SendNotification from "./Components/Notifications/SendNotification";
import ManageNotification from "./Components/Notifications/ManageNotification";
import Role from "./Components/Role/Role";
import CategoriesMaster from "./Components/CategoriesMaster/CategoriesMaster";
import Bulkupload from "./Components/Categories/Bulkupload/Bulkupload";
import Manageproduct from "./Components/Product/Manageproduct/Manageproduct";
import VariantMaster from "./Components/Variantmaster/variantmaster";
import Flatoffers from "./Components/FlatOffers/Flatoffers";
import MobileUsers from "./Components/Mobileusers/MobileUsers";
import Colourmaster from "./Components/Colourmaster/Colourmaster";
import SizeMaster from "./Components/SizeMaster/SizeMaster";
import Purchase from "./Components/Purchase/Purchase";
import Stock from "./Components/Stock/Stock";
import Offerpage from "./Components/Offerpage/Offerpage";
import Vieworder from "./Components/Vieworder/Vieworder";
import Cellularmemorystoragecapacity from "./Components/Cellularmemorystoragecapacity/Cellularmemorystoragecapacity";
import Processorspeed from "./Components/processorspeed/processorspeed";
import Gps from "./Components/Gps/Gpstracting";
import Cameraresolution from "./Components/CameraResolution/Cameraresolution";
import Phoneresolution from "./Components/PhoneResolution/Phoneresolution";
import Refreshrate from "./Components/RefreshRate/Refreshrate";
import Ramsize from "./Components/RamSize/Ramsize";
import Sensorresolution from "./Components/SensorResolution/Sensorresolution";
import Shootingmodes from "./Components/ShootingModes/Shootingmodes";
import Cellulartechnology from "./Components/Cellulartechnology/Cellulartechnology";
import Simcard from "./Components/Simcard/Simcard";
import Operatingsystems from "./Components/Operatingsystems/Operatingsystems";
import Mobilephonehuman from "./Components/Mobilephonehumaninterface/Mobilephonehuman";
import Cellularphone from "./Components/Cellularphonephotosensor/Cellularphone";
import Videocapture from "./Components/Videocapture/Videocapture";
import Itemweight from "./Components/Itemweight/Itemweight";
import Includedcomponents from "./Components/Includedcomponents/Includedcomponents";
import Phonebiometric from "./Components/Mobilephonebiometric/Phonebiometric";
import Connectortype from "./Components/Cellphoneconnectortype/Connectortype";
import Connectivitytechnology from "./Components/Connectivitytechnology/Connectivitytechnology";
import Screensize from "./Components/Screensize/Screensize";
import Displaytype from "./Components/Displaytype/Displaytype";
import Devicefeatures from "./Components/Devicefeatures/Devicefeatures";
import Slotcount from "./Components/Slotcount/Slotcount";
import Deliverycharge from "./Components/Deliverycharge/Deliverycharge";
import Shortby from "./Components/ShortBy/Shortby";
import Batterycapacity from "./Components/BatteryCapacity/Batterycapacity";
import Shopmaster from "./Components/Shopmaster/Shopmaster";
import Faq from "./Components/Faq/Faq";
import Setting from "./Components/Settings/Setting";
import PrivacyPolicy from "./Components/Policy/PrivacyPolicy";
import Aboutus from "./Components/Policy/Aboutus";
import DeliveryPolicy from "./Components/Policy/DeliveryPolicy";
import TermsandCondition from "./Components/Policy/TermsandCondition";
import RefundPolicy from "./Components/Policy/RefundPolicy";
import Orderreport from "./Components/Orderreport/Orderreport";
import Roleassign from "./Components/Role/Roleassign";
import Variants from "./Components/Variant/Variant";
import EditProduct from "./Components/Product/Manageproduct/EditProduct";
import FreeProduct from "./Components/FreeProduct/FreeProduct";
import SmarTv from "./Components/SmartTVFeatures/SmarTv";
import Sound from "./Components/Sound/Sound";
import SupportedInternet from "./Components/SupportedInternetServices/SupportedInternet";
import ProductDimension from "./Components/ProductDimensions/ProductDimension";
import DisplayPage from "./Components/Display/DisplayPage";
import AutoMaster from "./Components/AutoMaster/AutoMaster";
import ViewMaster from "./Components/AutoMaster/ViewMaster";
import ServicePage from "./Components/Service/ServicePage";
import Highlight_Master from "./Components/Highlight_Master/Highlight_Master";
import Variant_Master from "./Components/Variant_Master/Variant_Master";
import Auto_Variant_Master from "./Components/AutoVariantMaster/Auto_Variant_Master";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/orderlist" element={<Orderlist />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/addcategories" element={<Addcategories />} />
            <Route path="/categoriesorder" element={<Categoriesorder />} />
            <Route
              path="/productsalesreport"
              element={<Productsalesreport />}
            />
            <Route path="/salesreport" element={<Salesreport />} />
            <Route path="/units" element={<Units />} />
            <Route path="/taxes" element={<Taxes />} />
            <Route path="/product" element={<Addproduct />} />
            <Route path="/variant" element={<Variants />} />
            <Route path="/brands" element={<Brands />} />
            <Route path="/homesliders" element={<Homeslider />} />
            <Route path="/managehomeslider" element={<Managehomeslider />} />
            <Route path="/Withdrawalrequest" element={<Withdrawalrequest />} />
            <Route path="/ReturnRequest" element={<ReturnRequest />} />
            <Route path="/systemusers" element={<Systemusers />} />
            <Route path="/addofferimage" element={<Offerimage />} />
            <Route path="/manageofferimage" element={<Manageimage />} />
            <Route path="/addpromo" element={<Addpromo />} />
            <Route path="/managepromo" element={<Managepromo />} />
            <Route path="/adddelivery" element={<Adddelivery />} />
            <Route path="/managedelivery" element={<Managedelivery />} />
            <Route path="/addsection" element={<Addsection />} />
            <Route path="/managesection" element={<Managesection />} />
            <Route path="/sendnotification" element={<SendNotification />} />
            <Route path="/setting" element={<Setting />} />
            <Route
              path="/managenotification"
              element={<ManageNotification />}
            />
            <Route path="/AutoMaster" element={<AutoMaster />} />
            <Route path="/ViewMaster" element={<ViewMaster />} />
            <Route path="/offer" element={<Offerpage />} />
            <Route path="/role" element={<Role />} />
            <Route path="/vieworder" element={<Vieworder />} />
            <Route path="/categoriemaster" element={<CategoriesMaster />} />
            <Route path="/bulkupload" element={<Bulkupload />} />
            <Route path="/manageproduct" element={<Manageproduct />} />
            <Route path="/variantmaster" element={<VariantMaster />} />
            <Route path="/flatoffers" element={<Flatoffers />} />
            <Route path="/mobileusers" element={<MobileUsers />} />
            <Route path="/colourmaster" element={<Colourmaster />} />
            <Route path="/sizemaster" element={<SizeMaster />} />
            <Route path="/purchase" element={<Purchase />} />
            <Route path="/stock" element={<Stock />} />
            <Route path='/editproduct' element={<EditProduct />} />
            <Route
              path="cellularmemorystoragecapacity"
              element={<Cellularmemorystoragecapacity />}
            />
            <Route path="processorspeed" element={<Processorspeed />} />
            <Route path="gps" element={<Gps />} />
            <Route path="cameraresolution" element={<Cameraresolution />} />
            <Route path="phoneresolution" element={<Phoneresolution />} />
            <Route path="refreshrate" element={<Refreshrate />} />
            <Route path="ramsize" element={<Ramsize />} />
            <Route path="sensorresolution" element={<Sensorresolution />} />
            <Route path="shootingmodes" element={<Shootingmodes />} />
            <Route path="/biometric" element={<Phonebiometric />} />
            <Route path="/connectivity" element={<Connectivitytechnology />} />
            <Route path="/devicefeatures" element={<Devicefeatures />} /> 
            <Route path="slotcount" element={<Slotcount />} />
            <Route path="/size" element={<Screensize />} />
            <Route path="/displaytype" element={<Displaytype />} />
            <Route path="connector" element={<Connectortype />} />
            <Route path="/include" element={<Includedcomponents />} />
            <Route path="/humanint" element={<Mobilephonehuman />} />
            <Route path="/item" element={<Itemweight />} />
            <Route path="/cellularphone" element={<Cellularphone />} />
            <Route path="/videocapture" element={<Videocapture />} />
            <Route path="/os" element={<Operatingsystems />} />
            <Route path="/sim" element={<Simcard />} />
            <Route path="cellulartech" element={<Cellulartechnology />} />
            <Route
              path="cellularmemorystoragecapacity"
              element={<Cellularmemorystoragecapacity />}
            />
            <Route path="deliverycharge" element={<Deliverycharge />} />
            <Route path="shortby" element={<Shortby />} />
            <Route path="batterycapacity" element={<Batterycapacity />} />
            <Route path="shop" element={<Shopmaster />} />
            <Route path="faq" element={<Faq />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="Aboutus" element={<Aboutus />} />
            <Route path="deliverypolicy" element={<DeliveryPolicy />} />
            <Route path="termsandcondition" element={<TermsandCondition />} />
            <Route path="refundpolicy" element={<RefundPolicy />} />
            <Route path="orderreport" element={<Orderreport />} />
            <Route path="roleassign" element={<Roleassign />} />
            <Route path="freeproduct" element={<FreeProduct />} />
            <Route path="Displaytype" element={<Displaytype />} />
            <Route path="smarttv" element={<SmarTv />} />
            <Route path="sound" element={<Sound />} />
            <Route path="supportinternet" element={<SupportedInternet />} />
            <Route path="productdimension" element={<ProductDimension />} />
            <Route path="displaytv" element={<DisplayPage />} />
            <Route path="service" element={<ServicePage></ServicePage>}/>
            <Route path="Highlight_Master" element={<Highlight_Master/>}/>
            <Route path="Variant_Master" element={<Variant_Master/>}/>
            <Route path="Auto_Variant_Master" element={<Auto_Variant_Master/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
